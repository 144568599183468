import { ListItem } from "../constants/types"

const usedPage = new Set()

export function findNext(list: ListItem[]): number | undefined {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (usedPage.has(item.id)) {
      continue
    }
    return i
  }
  return
}

export function setPlayed(item: ListItem) {
  usedPage.add(item.id)
}


export function baiduApp(current: { lat: number ,lng: number }, target: { lat: number, lng: number }) {
  let hasApp = true;
  const delay = 1000;
  // const latCurrent = 32.025;
  // const lngCurrent = 118.795;
  // const urlObject = {lat: 32.025, lng: 118.795, city: '南京'}
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      if (hasApp) {
        resolve()
        // alert('跳转到百度地图APP');
      } else {
        reject(new Error('未安装百度地图APP'))
        // alert('未安装百度地图APP'); // 此处建议使用msg提示框，显示个两三秒自动消失。
      }
      document.body.removeChild(iframeDom);
    }, 2000)
    const queryStr = `?origin=name:我的位置|latlng:${current.lat},${current.lng}&destination=${target.lat},${target.lng}&region=上海&coord_type=bd09ll`;
    const url = `baidumap://map/direction?${queryStr}&output=html`;
    const timeStart = Date.now();
    const iframeDom = document.createElement("iframe");
    iframeDom.setAttribute('src', url);
    iframeDom.setAttribute('style', 'display:none');
    document.body.appendChild(iframeDom);
    setTimeout(function () {
      const timeEnd = Date.now();
      const timeInterval = timeEnd - timeStart;
      // @ts-ignore
      // document.getElementById('timeListion').innerHTML = '百度地图：' + timeInterval;
      if (!timeStart || timeEnd - timeStart < delay + 100) {
        hasApp = false;
      }
    }, delay);
  })
}

export function gaodeApp(current: { lat: number, lng: number}, target: { lat: number, lng: number }) {
  let hasApp = true;
  const delay = 1000;
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      if (hasApp) {        //
        // alert('跳转到百度地图APP');
        resolve()
      } else {
        reject(new Error('未安装高德地图APP'))
        // alert('未安装高德地图APP'); // 此处建议使用msg提示框，显示个两三秒自动消失。
      }
      document.body.removeChild(iframeDom);
    }, 2000)
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let url = ``;
    if (isAndroid) {
      url = `androidamap://navi?sourceApplication=appname&&lat=${target.lat}&lon=${target.lng}&dev=1&style=2`;
    } else if (isiOS) {
      url = `iosamap://path?sourceApplication=applicationName&sid=BGVIS1&slat=${current.lat}&slon=${current.lng}&sname=A&did=BGVIS2&dlat=${target.lat}&dlon=${target.lng}&dname=B&dev=0&t=0`;
    }
    const timeStart = Date.now();
    const iframeDom = document.createElement("iframe");
    iframeDom.setAttribute('src', url);
    iframeDom.setAttribute('style', 'display:none');
    document.body.appendChild(iframeDom);
    setTimeout(function () {
      const timeEnd = Date.now();
      const timeInterval = timeEnd - timeStart;
      // @ts-ignore
      // document.getElementById('timeListion').innerHTML = '高德地图：' + timeInterval;
      if (!timeStart || timeEnd - timeStart < delay + 100) {
        hasApp = false;
      }
    }, delay);
  })

}
export async function callMap(currentPos: {lat: number, lng: number}, target: { lat: number, lng: number }) {
  try {
    await baiduApp(currentPos, target)
  } catch (e) {
    await gaodeApp(currentPos, target)
  }
}

import React, { FC, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { transformTime } from '../utils/time'
import { AudioPlayerState, useAudioPlayerContext } from './AudioPlayerContext'

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  color: #fff;
  height: 200px;
  z-index: 1;
`

const rippleOutter = keyframes`
  0% {
    border-color: rgba(171, 250, 126, 1);
    transform: scale3d(0.33, 0.33, 0);
  }

  50% {
    border-color: rgba(171, 250, 126, 0.3);

  }

  100% {
    border-color: rgba(171, 250, 126, 0);
    transform: scale3d(1.6, 1.6, 1);
  }

`

const rippleInner = keyframes`
  0%{
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const Title = styled.div`
  main {
    font-size: 33px;
    font-weight: 900;
    line-height: 33.9px;
    margin-bottom: 10px;
  }

  .status {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 24px;
    opacity: 0;
    transition: opacity 300ms;

    .outter,
    .inner {
      border-radius: 50%;
      position: absolute;
    }

    .outter {
      width: 18px;
      height: 18px;
      border: 3px solid transparent;
      margin-right: 6px;
      left: 0;
      top: 0;
      animation: 2s ${rippleOutter} ease-out infinite;
    }

    /* Ellipse 4 */

    .inner {
      width: 6px;
      height: 6px;
      left: 6px;
      top: 6px;
      background: #abfa7e;
      animation: 1s ${rippleInner} infinite alternate;
    }

    /* 漫游中 */

    .text {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #abfa7e;
      transform: translate3d(-12px, 0, 0);
      opacity: 0;
      transition: all 1s 500ms;
    }

    &.processing {
      opacity: 1;

      .text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
`

const Dashboard = styled.div`
  text-align: right;

  .item {
    margin-bottom: 10px;
  }

  .title {
    font-size: 13px;
    font-weight: 600;
    opacity: 0.5;
    line-height: 13px;
    margin-bottom: 2px;
  }

  .content {
    font-size: 22px;
    line-height: 23.2px;
    font-style: italic;

    .unit {
      font-size: 15px;
      line-height: 16px;
    }
  }
`

export interface HeaderProps {
  speed: number | null
}
const Header: FC<HeaderProps> = ({ speed }) => {
  const { duration, playState } = useAudioPlayerContext()

  const { hour, min, second } = useMemo(() => transformTime(duration), [
    duration,
  ])

  const isPlaying = playState === AudioPlayerState.PLAYING
  return (
    <Container>
      <Title>
        <main>
          <div>走来走去</div>
          <div>小助手</div>
        </main>

        <div className={`status ${isPlaying ? 'processing' : ''}`}>
          <div className="outter flex-center"></div>
          <div className="inner"></div>

          <div className="text">漫游中</div>
        </div>
      </Title>
      <Dashboard>
        <div className="item">
          <div className="title">漫游时长</div>
          <div className="content">
            {hour > 0 && (
              <>
                {hour}
                <span className="unit">hour</span>
              </>
            )}
            {duration >= 60 * 1000 && (
              <>
                {min}
                <span className="unit">min</span>
              </>
            )}

            {second}
            <span className="unit">s</span>
          </div>
        </div>
        {speed && (
          <div className="item">
            <div className="title">当前速度</div>
            <div className="content">
              {speed.toFixed(2)} <span className="unit">km/s</span>
            </div>
          </div>
        )}
        {/* <div className="item">
          <div className="title">行动距离</div>
          <div className="content">
            50
            <span className="unit">m</span>
          </div>
        </div> */}
      </Dashboard>
    </Container>
  )
}

export default Header

export enum Source {
  WIKI = 'WIKI',
  RESTROOM = 'RESTROOM',
  CUSTOM = 'CUSTOM',
  AD = 'AD'
}

export const FAKE_POS = {
  lng: 121.513,
  lat: 31.30424,
}
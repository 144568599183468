
export const transformTime = (timestamp: number) => {
  const time = timestamp / 1000
  const hour = Math.floor(time / 360)
  const minSecond = time % 360
  const min = Math.floor(minSecond / 60)
  const second = minSecond % 60
  
  return {
    hour, min,
    second
  }
}
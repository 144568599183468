import React, { ComponentProps, FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Source } from '../constants'
import { COLORS } from '../constants/styles'
import { ListItem } from '../constants/types'
import { callMap } from '../utils/map'
import { useAudioPlayerContext } from './AudioPlayerContext'

export const Container = styled.div`
  height: 197px;
  width: 266px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  padding: 18px 18px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  & + & {
    margin-left: 22px;
  }

  transition: transform 300ms;
  &:active {
    transform: scale3d(0.95, 0.95, 1);
  }
`

const Header = styled.header`
  margin-bottom: 11px;
  justify-content: space-between !important;

  .subtitle {
    opacity: 0.5;
    font-family: PingFang SC;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    margin-bottom: 5px;
  }

  .title {
    font-family: PingFang SC;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
  }

  .navigator {
    background-color: ${({ color }) => color};
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

const Main = styled.div`
  font-family: PingFang SC;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #bdbdbd;
  flex: 1;
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Bar = styled.div`
  height: 10px;
  background: ${({ color }) => color};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

interface CardProps extends ListItem, ComponentProps<any> {
  desc: ReactNode
  type: Source
}

const Card: FC<CardProps> = ({
  title,
  desc,
  type,
  lat,
  lng,
  dist,
  id,
  onClick,
  ...props
}) => {
  const { position, actions, current, list } = useAudioPlayerContext()
  const { coords } = position
  const isCurrentProcessing = list[current].id === id
  let text = ''
  if (dist > 1000) {
    text = `${(dist / 1000).toFixed(1)}km`
  } else {
    text = `${dist.toFixed(0)}m`
  }
  return (
    <Container onClick={onClick}>
      <Header color={COLORS[type]} className="flex-center">
        <div>
          <div className="subtitle">
            {isCurrentProcessing ? '正在进行' : text}
          </div>
          <div className="title">{title}</div>
        </div>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAMAAAAPdrEwAAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMAZoDsD99fzzPvME0qQQZwWRo5IBPs34KHAAAA/ElEQVRYw+3Wu24CMRSE4VnH2Xhje1lu7/+qEQpmhBCIYqZAOn9jaYrP7UEURVH0opphaj7/0BbLZ9pqmbZapq2Wactl2nqZtl6mrZdp62Xaepm2XqYtkNU2ZZf9uzynE6Cxv6/vslDW2CmNL8YAaOyEGz0GaOwE0mPQ2DuA9Bh0kWZeOrdW+lFOr63O06Uipvf1okppwhZ67XS19H6eTHQjKqbL5KIf5G1rGvrrjq3ttILp6JLBlHQlLKYLYKILXHSBiz7ARW+w0dlGd9jobKM7bHS20RU2uvnobKMrbPTBRx999MlHrz4ad33mfR20ge7pvx2iKIqi6N3+AOCSMy9wHbjOAAAAAElFTkSuQmCC"
          alt=""
          className="navigator"
          onClick={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            try {
              await callMap(
                {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
                { lat, lng },
              )
            } catch (e) {
              console.error('e', e)
            }
            // location.href = `https://restapi.amap.com/v3/direction/walking?destination=${lng},${lat}&origin=${coords.longitude},${coords.altitude}&zoom=10&key=e257f5b146ffaba20b46cdc6a2b10bdf`
          }}
        />
      </Header>

      <Main>{desc}</Main>
      <Bar color={COLORS[type]}></Bar>
    </Container>
  )
}

Card.displayName = 'Card'

export default Card

import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState,} from 'react'
import {useInterval} from 'react-use'
import {BASE_URL} from '../constants/proxy'
import {ListItem} from '../constants/types'
import {AudioPlayerState, useAudioPlayerContext} from './AudioPlayerContext'
import {findNext, setPlayed} from "../utils";

export interface AudioPlayerProps {}

// @ts-ignore
const AudioPlayer: FC<AudioPlayerProps> = forwardRef((props, ref) => {
  const audioDom = useRef<HTMLAudioElement>(null)
  const isFirstPlay = useRef<boolean>(true)
  const [url, setUrl] = useState('')
  // const [current, setCurrent] = useState<number>()
  // const [isAudioPlaying, toggleAudioPlaying] = useState(false)
  // const [isTiming, setIsTiming] = useState(false)
  const {
    list,
    playState,
    actions,
    setPlayState,
    duration,
    isAudioPlaying,
    setAudioPlaying,
    setDuration,
    setCurrent,
    current
  } = useAudioPlayerContext()
  const isTiming = playState === AudioPlayerState.PLAYING
  const handlePlayEnd = useCallback(() => {
    if (typeof current === 'undefined') {
      return
    }
    console.log('on play end', list, current)
    const index = findNext(list)
    if (index !== undefined) {
      console.log('call set curernt?', index)
      setCurrent(index)
    }
    setAudioPlaying(false)
    setPlayState(AudioPlayerState.PAUSED)
  }, [setCurrent, current, list, setAudioPlaying, setPlayState])

  useInterval(
    () => {
      setDuration((prev) => prev + 1000)
    },
    isTiming ? 1000 : null,
  )

  const setMediaSession = (data: ListItem): boolean => {
    if ('mediaSession' in navigator && audioDom.current) {
      const cover = data.image
      // @ts-ignore typescript不支持
      navigator.mediaSession.metadata = new MediaMetadata({
        title: data.title,
        album: data.extract.slice(0, 20),
        // artist: data.author,
        artwork: cover
          ? [
              { src: cover, sizes: '96x96', type: 'image/png' },
              { src: cover, sizes: '128x128', type: 'image/png' },
              { src: cover, sizes: '192x192', type: 'image/png' },
              { src: cover, sizes: '256x256', type: 'image/png' },
              { src: cover, sizes: '384x384', type: 'image/png' },
              { src: cover, sizes: '512x512', type: 'image/png' },
            ]
          : undefined,
      })
      // @ts-ignore
      navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        audioDom.current!.currentTime = Math.max(
          audioDom.current!.currentTime - 10,
          0,
        )
      })

      // @ts-ignore
      navigator.mediaSession.setActionHandler('seekforward', (details) => {
        audioDom.current!.currentTime = Math.min(
          audioDom.current!.currentTime + 30,
          audioDom.current!.duration,
        )
      })
      try {
        // @ts-ignore
        navigator.mediaSession.setActionHandler('seekto', (event) => {
          console.log('> User clicked "Seek To" icon.')
          if (event.fastSeek && 'fastSeek' in audioDom.current!) {
            // @ts-ignore
            audioDom.current.fastSeek(event.seekTime)
            return
          }
          audioDom.current!.currentTime = event.seekTime
        })
      } catch (error) {
        console.log(
          'Warning! The "seekto" media session action is not supported.',
        )
      }
    }
    return true
  }

  useLayoutEffect(() => {
    if (!audioDom.current) {
      return
    }
    audioDom.current.addEventListener('ended', handlePlayEnd)

    return () => {
      audioDom.current &&
        audioDom.current.removeEventListener('ended', handlePlayEnd)
      // setIsTiming(false)
    }
  }, [handlePlayEnd, audioDom.current])

  useEffect(() => {
    if (typeof current === 'undefined' || !list[current] || isAudioPlaying) {
      return
    }
    const { dist, title, extract, source } = list[current]
    let text
    if (dist <= 100) {
      text = `${title}就在你的附近，${extract}`
    } else {
      const distance = dist > 1000 ? `${(dist / 1000).toFixed(1)}公里` : `${dist.toFixed(0)}米`
      text = `距离你${distance}是${title}，${extract}`
    }
    if (playState === AudioPlayerState.UNINITED) {
      return
    }
    setAudioPlaying(true)
    setPlayState(AudioPlayerState.PLAYING)
    setPlayed(list[current])
    const url = `${BASE_URL}/api/tts/tts?text=${text}&source=${source}`
    setUrl(url)
    if (audioDom.current) {
      audioDom.current.src = url
      audioDom.current.currentTime = 0
      audioDom.current.play()
    }
    setTimeout(() => {
      setMediaSession(list[current])
    }, 0)
  }, [playState, current, list, setAudioPlaying, audioDom, isAudioPlaying, setPlayState])

  useEffect(() => {
    setCurrent((prev) => (typeof prev === 'undefined' ? 0 : prev))
  }, [list])

  const pause = useCallback((): boolean => {
    if (!audioDom.current) {
      return false
    }
    audioDom.current.pause()
    // setIsTiming(false)
    return true
  }, [])

  const stop = useCallback((): boolean => {
    if (audioDom.current) {
      pause()
      audioDom.current.currentTime = 0
    }
    return true
  }, [pause])

  const play = useCallback(
    async (index = null) => {
      if (!audioDom.current) {
        return
      }

      if (index !== null) {
        setCurrent(index)
      }

      stop()
      try {
        if (isFirstPlay.current) {
          isFirstPlay.current = false
        }
        await audioDom.current.play()
        // setIsTiming(true)
      } catch (e) {}
    },
    [stop, isFirstPlay.current],
  )

  const resume = useCallback((): boolean => {
    if (!audioDom.current) {
      return false
    }

    play()
    return true
  }, [play])

  actions.current = () => ({
    root: audioDom.current,
    play,
    resume,
    pause,
    stop,
  })
  // @ts-ignore
  useImperativeHandle(ref, actions.current)

  useEffect(() => {
    switch (playState) {
      case AudioPlayerState.PLAYING:
        resume()
        break
      case AudioPlayerState.PAUSED:
        pause()
        break
      case AudioPlayerState.STOPPED:
        stop()
        break
    }
  }, [playState])

  console.log('render audio dom', url)

  return <audio ref={audioDom} />
})

AudioPlayer.displayName = 'AudioPlayer'

export default AudioPlayer

import axios from 'axios'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Container, defaultImage } from './App.style'
import AudioPlayer from './components/AudioPlayer'
import { useAudioPlayerContext } from './components/AudioPlayerContext'
import Footer from './components/Footer'
import Header from './components/Header'
import Main from './components/Main'
import { Source } from './constants'
import { BASE_URL } from './constants/proxy'

const Modal = styled.div`
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.793082) 6.67%,
    rgba(0, 0, 0, 0.771592) 13.33%,
    rgba(0, 0, 0, 0.734672) 20%,
    rgba(0, 0, 0, 0.682071) 26.67%,
    rgba(0, 0, 0, 0.61458) 33.33%,
    rgba(0, 0, 0, 0.534493) 40%,
    rgba(0, 0, 0, 0.445847) 46.67%,
    rgba(0, 0, 0, 0.354153) 53.33%,
    rgba(0, 0, 0, 0.265507) 60%,
    rgba(0, 0, 0, 0.18542) 66.67%,
    rgba(0, 0, 0, 0.117929) 73.33%,
    rgba(0, 0, 0, 0.0653279) 80%,
    rgba(0, 0, 0, 0.028408) 86.67%,
    rgba(0, 0, 0, 0.00691776) 93.33%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 0;
`

function App() {
  const {
    addList,
    sources,
    position,
    setPosition,
    list,
    current,
  } = useAudioPlayerContext()
  const { longitude, latitude, speed } = position.coords

  const currentItem = list && list[current]

  const handleSearch = useCallback(() => {
    if (!latitude || !longitude) {
      return
    }
    axios
      .post(`${BASE_URL}/api/lbs/search`, {
        lng: longitude,
        lat: latitude,
        sources,
      })
      .then(({ data: { data } }) => {
        addList(data)
      })
  }, [latitude, longitude, sources.length])

  useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(
      (position: Position) => {
        setPosition(position)
        // setPosition({ coords: { longitude: FAKE_POS.lng, latitude: FAKE_POS.lat }} as any)
      },
    )

    return () => {
      navigator.geolocation.clearWatch(watchID)
    }
  }, [])

  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  // useEffect(() => {
  //   const handler = (e: any) => {
  //     e.preventDefault()
  //   }
  //   document.body.addEventListener('touchmove', handler, { passive: false })
  //   return () => document.body.removeEventListener('touchmove', handler)
  // }, [])

  const ref = useRef<any>(null)

  const image = currentItem && currentItem.image

  return (
    <Container
      style={{
        backgroundImage: `url('${image || defaultImage}')`,
        backgroundPosition: 'center',
      }}
    >
      <Modal></Modal>
      <AudioPlayer ref={ref}></AudioPlayer>
      <Header speed={speed}></Header>
      <div style={{ marginBottom:'70px' }}>
        <Main></Main>
        <Footer></Footer>
      </div>
    </Container>
  )
}

export default App

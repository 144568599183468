import uniqBy from 'lodash.uniqby'
import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import {Source} from '../constants'
import {ListItem} from '../constants/types'

export enum AudioPlayerState {
  // 未初始化
  UNINITED = 'UNINITED',

  // 初始化
  INITED = 'INITED',

  // 已初始化并且有源的时候进入准备状态
  READY = 'READY',

  // 正在播放状态
  PLAYING = 'PLAYING',

  // 暂停状态
  PAUSED = 'PAUSED',

  // 停止状态，
  STOPPED = 'STOPPED',
}
export interface AudioPlayerContextValue {
  playState: AudioPlayerState
  setPlayState: Dispatch<SetStateAction<AudioPlayerState>>
  list: ListItem[]
  addList: (list: ListItem[]) => void
  clearList: () => void
  sources: Source[]
  setSources: Dispatch<SetStateAction<Source[]>>
  position: Position
  setPosition: Dispatch<SetStateAction<Position>>
  actions: MutableRefObject<() => any>
  duration: number
  setDuration: Dispatch<SetStateAction<number>>
  setCurrent: Dispatch<SetStateAction<number>>
  current: number
  setAudioPlaying: Dispatch<SetStateAction<boolean>>,
  isAudioPlaying: boolean,
}

const context = createContext<AudioPlayerContextValue>({
  playState: AudioPlayerState.UNINITED,
  setPlayState: () => null,
  list: [] as ListItem[],
  addList: () => null,
  clearList: () => null,
  sources: [],
  setSources: () => null,
  position: ({
    coords: {
      latitude: 0,
      longitude: 0,
      speed: 0,
    },
    timestamp: 0,
  } as unknown) as Position,
  setPosition: () => null,
  actions: {
    current: () => ({}),
  },
  duration: 0,
  setDuration: () => null,
  current: 0,
  setCurrent: () => null,
  setAudioPlaying: () => null,
  isAudioPlaying: false,
})

export const AudioPlayerProvider = (props?: any) => {
  const [playState, setPlayState] = useState<AudioPlayerState>(
    AudioPlayerState.UNINITED,
  )
  const [isAudioPlaying, setAudioPlaying] = useState(false)
  const [list, setList] = useState<ListItem[]>([])
  const [current, setCurrent] = useState(0)

  const addList = useCallback((list: ListItem[]) => {
    setList((prevList) => {
      // const nextList = Array.from(prevList) as ListItem[]
      return uniqBy(list, 'id').sort(
        (prev: any, next: any) => prev.dist - next.dist,
      )
    })
  }, [])

  const [sources, setSources] = useState([Source.WIKI, Source.CUSTOM])
  const [position, setPosition] = useState<Position>(({
    coords: {},
    timestamp: 0,
  } as unknown) as Position)
  const actions = useRef(() => ({}))
  const [duration, setDuration] = useState(0)
  const value = useMemo<AudioPlayerContextValue>(
    () => ({
      playState,
      setPlayState,
      list,
      addList,
      clearList: () => setList([]),
      sources,
      setSources,
      position,
      setPosition,
      actions,
      duration,
      setDuration,
      setCurrent,
      current,
      isAudioPlaying,
      setAudioPlaying,
    }),
    [isAudioPlaying, setAudioPlaying, setCurrent, current, playState, list, addList, sources, position, duration, actions.current],
  )
  return <context.Provider value={value} {...props}></context.Provider>
}

export const useAudioPlayerContext = () => {
  return useContext(context)
}
